<template>
  <div id="app">
    <div class="header">
      <div class="navi-top"></div>
      <div class="logo-wrap">
        <div class="logo"></div>
        <span class="logo-text">股票代码：<span style="color:#e02020;">603516</span></span>
      </div>
      <div class="navigator">
        <span class="nav-title">加入我们</span><i class="nav-arrow"></i><span>{{ navName }}</span>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  computed: {
    navName(){
      let str = ''
      let routeName = this.$route.name
      let w = window.innerWidth
      switch(routeName){
        case 'join':
          str = ''
          break;
        case 'register':
          if(w <= 750){
            str = '应聘登记'
          }else{
            str = '上传简历'
          }
          break;
        case 'success':
          if(w <= 750){
            str = '应聘登记'
          }else{
            str = '提交成功'
          }
          
          break;
      }
      return str
    },
   

  }
}
</script>
<style>

</style>
