// import 'core-js/stable';
// import 'regenerator-runtime/runtime';
import Vue from 'vue'
import App from './App.vue'
import router from './router'

import VueToast from 'vue-toast-notification';

import 'vue-toast-notification/dist/theme-default.css';

// import VConsole from 'vconsole'

import '@/assets/css/common.css'

import '@/assets/icon/iconfont.css'

Vue.use(VueToast, {
  position: 'top',
  duration: 2000
})

// new VConsole()

// import '@/mock/index.js'
// Vue.config.debug = true
Vue.config.productionTip = false

new Vue({
  router,
  // render: h => h(App)
  render: function(h){
    return h(App)
  }
}).$mount('#app')
