import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/join'
  },{
    path: '/join',
    name: 'join',
    component: () => import('../views/join.vue')
  },{
    path: '/register',
    name: 'register',
    component: () => import('../views/register.vue')
  },{
    path: '/success',
    name: 'success',
    component: () => import('../views/success.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
